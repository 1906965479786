import request from '@/utils/request'

export const postImgData = (data) => {
  return request({
    method: 'POST',
    url: '/v2/images',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

export const getDeliverDate = (id) => {
  return request({
    method: 'GET',
    url: `/cms/delivery_orders/${id}/available_delivery_dates`
  })
}
